import React, { FC } from 'react';
import styles from './bejmatTile.module.scss';

interface Props {
  image: {
    url: string;
  };
  name: string;
  sizes: string;
}

const BejmatTile: FC<Props> = ({ image, name, sizes }: Props) => {
  return (
    <section className={styles.wrapper}>
      {image !== null && <img src={image.url} alt="tile" className={styles.image} />}
      {name !== null && <p className={styles.name}>{name}</p>}
      {sizes !== null &&
        sizes.split(',').map((size, i) => (
          <p className={styles.size} key={i}>
            {size}
          </p>
        ))}
    </section>
  );
};

export default BejmatTile;
