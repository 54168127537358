import { graphql } from 'gatsby';
import React, { FC } from 'react';
import Layout from '../components/layout/layout';
import SEO from '../components/seo';
import styles from './morrocanTile.module.scss';

import img5 from '../images/zellige/photo.png';

import ZelligeTile from '../components/morrocan-tile/zellige-tile';
import BejmatTile from '../components/morrocan-tile/bejmat-tile';
import MosaicExample from '../components/morrocan-tile/mosaic-example';

import cn from 'classnames';
import Background2 from '../images/2_Moroccan_Tile_Studio.jpg';
import savePngIcon from '../images/pdf-icon.png';

import mos2 from '../images/bejmat/Warda.jpg';
import mos3 from '../images/bejmat/Warda_Vector.jpg';

import proc2 from '../images/bejmat/Color_wheel.png';
import Back from '../images/moroccan_background.jpg';
import FileSaver from 'file-saver';

const sizeInfo = [
  {
    title: 'SIZE',
    data: ['4"x 4"', '2"x 2"'],
    dataTwo: ['2"x 6"'],
    dataThree: ['Mosaic Bahia 12"x 12"'],
  },
  {
    title: 'THICKNESS',
    data: ['1/2"-5/8"'],
    dataTwo: ['5/8"'],
    dataThree: ['Approximately 1/2"'],
  },
];

const techInfo = [
  { title: 'FLOOR', data: ['Yes*'] },
  { title: 'WALL', data: ['Yes'] },
  { title: 'FROST RESISTANT', data: ['NO'] },
  { title: 'INDOOR', data: ['Yes'] },
  { title: 'OUTDOOR', data: ['Yes'] },
  { title: 'WET AREA', data: ['Yes'] },
  { title: 'VARIATION', data: ['HIGH'] },
];

export const query = graphql`
  {
    strapi {
      moroccanTile {
        zellige_and_bejmat {
          url
        }
        custom_mosaics {
          url
        }
      }

      mosaicColors(sort: "sortIndex:asc") {
        name
        color_image {
          url
        }
      }

      morrocanBejmatTiles(sort: "sortIndex:asc") {
        sizes
        name
        image {
          url
        }
      }
      morrocanMosaicBahias(sort: "sortIndex:asc") {
        sizes
        name
        image {
          url
        }
      }
      morrocanMosaics(sort: "sortIndex:asc") {
        name
        image_schema {
          url
        }
        image_colored {
          url
        }
      }
      morrocanZelligeTiles(sort: "sortIndex:asc") {
        name
        sizes
        image {
          url
        }
      }
    }
  }
`;

interface Props {
  data: {
    strapi: {
      moroccanTile: {
        zellige_and_bejmat: {
          url: string;
        };
        custom_mosaics: {
          url: string;
        };
      };
      mosaicColors: Array<{
        name: string;
        color_image: {
          url: string;
        };
      }>;
      morrocanBejmatTiles: Array<{
        sizes: string;
        name: string;
        image: {
          url: string;
        };
      }>;
      morrocanMosaicBahias: Array<{
        sizes: string;
        name: string;
        image: {
          url: string;
        };
      }>;
      morrocanMosaics: Array<{
        name: string;
        image_schema: {
          url: string;
        };
        image_colored: {
          url: string;
        };
      }>;
      morrocanZelligeTiles: Array<{
        name: string;
        sizes: string;
        image: {
          url: string;
        };
      }>;
    };
  };
}

const MorrocanTile: FC<Props> = ({
  data: {
    strapi: {
      morrocanBejmatTiles,
      morrocanMosaicBahias,
      morrocanMosaics,
      morrocanZelligeTiles,
      mosaicColors,
      moroccanTile,
    },
  },
}: Props) => {
  return (
    <Layout isWhiteBackground={true}>
      <SEO title="Moroccan tile" />
      <section className={styles.wrapper}>
        <div className={styles.header}>
          <img src={Back} className={styles.image} />
          <div className={styles.border}></div>
          <div className={styles.titleContainer}>
            <p className={styles.title}>{'Moroccan Zellige & \n Bejmat Tile'}</p>
            <p className={styles.text}>STOCKED</p>
          </div>
        </div>

        <section className={styles.tilesWrapper}>
          <p className={styles.collectionName}>ZELLIGE</p>

          <div className={styles.tilesContainer}>
            {morrocanZelligeTiles?.map((item, i) => (
              <div key={i + `${item.name}`} className={styles.tile}>
                <ZelligeTile image={item.image} name={item.name} sizes={item.sizes} />
              </div>
            ))}
          </div>

          <p className={styles.collectionName}>BEJMAT</p>

          <div className={styles.tilesContainer}>
            {morrocanBejmatTiles?.map((item, i) => (
              <div key={i + `${item.name}`} className={styles.tile}>
                <BejmatTile image={item.image} name={item.name} sizes={item.sizes} />
              </div>
            ))}
          </div>

          {morrocanMosaicBahias.length && <p className={styles.collectionName}>MOSAIC BAHIA</p>}

          <div className={styles.tilesContainer}>
            {morrocanMosaicBahias?.map((item, i) => (
              <div key={i + `${item.name}`} className={styles.tile}>
                <BejmatTile image={item.image} name={item.name} sizes={item.sizes} />
              </div>
            ))}
          </div>
        </section>

        <section className={styles.infoWrapper}>
          <div className={styles.information}>
            <img src={`${img5}`} alt="image" className={styles.infoImage} />
            <div className={styles.sizeTechInformation}>
              <p className={styles.title}>Size Information</p>

              <div className={styles.dataWrapper}>
                {sizeInfo.map((item, i) => (
                  <div
                    className={styles.data}
                    key={i + `${item.title}`}
                    style={{ marginRight: '4rem' }}
                  >
                    <p className={styles.itemTitle}>{item.title}</p>
                    <div className={styles.divider}></div>
                    <div className={styles.sizeContainer}>
                      {item.data.map((size, i) => (
                        <p className={styles.size} key={i + `${size}`}>
                          {size}
                          {item.data.length - 1 > i ? ',' : ''}
                          <span>&nbsp;</span>
                        </p>
                      ))}
                    </div>
                    <div className={styles.sizeContainer}>
                      {item.dataTwo.map((size, i) => (
                        <p className={styles.size} key={i + `${size}2`}>
                          {size}
                          {item.dataTwo.length - 1 > i ? ',' : ''}
                          <span>&nbsp;</span>
                        </p>
                      ))}
                    </div>
                    <div className={styles.sizeContainer}>
                      {item.dataThree.map((size, i) => (
                        <p className={styles.size} key={i + `${size}2`}>
                          {size}
                          {item.dataTwo.length - 1 > i ? ',' : ''}
                          <span>&nbsp;</span>
                        </p>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className={styles.sizeTechInformation}>
              <p className={styles.title}>Technical Information</p>

              <div className={styles.dataWrapper}>
                {techInfo.map((item, i) => (
                  <div className={styles.data} key={i}>
                    <p className={styles.itemTitle}>{item.title}</p>
                    <div className={styles.dividerTech}></div>
                    <div className={styles.sizeContainer}>
                      {item.data.map((size, i) => (
                        <p className={styles.size} key={i}>
                          {size}
                          {item.data.length - 1 > i ? ',' : ''}
                          <span>&nbsp;</span>
                        </p>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <p className={styles.sizeText}>*Light Residential/Traffic</p>
            <br />
            <p className={styles.sizeText}>
              Zellige, Bejmat, and Bahia materials sold by full box only
            </p>
            <br />
            <p className={styles.sizeText}>10.77 SF per box (2"x2", 2"x6", 4"x4")</p>
            <br />
            <p className={styles.sizeText}>11.00 SF per box (Bahia / 2"x2" mesh-mosaics)</p>
            <br />
            <p className={styles.sizeText}>All sizes are nominal</p>
            <br />
            <p className={styles.sizeText}>
              As standard industry procedure, our recommendation is to order an additional 20%
              overage
            </p>
            <div className={styles.downloadPdfs}>
              {moroccanTile.zellige_and_bejmat?.url && (
                <div className={styles.downloadPdf}>
                  <p className={styles.title}>Zellige & Bejmat Tear Sheet</p>
                  <button
                    className={styles.buttonContainer}
                    onClick={() => {
                      FileSaver.saveAs(
                        moroccanTile.zellige_and_bejmat.url,
                        moroccanTile.zellige_and_bejmat.url?.split('/').pop(),
                      );
                    }}
                  >
                    <img className={styles.pngButtonImg} alt="icon image" src={savePngIcon} />
                  </button>
                </div>
              )}
              {moroccanTile.custom_mosaics?.url && (
                <div className={styles.downloadPdf}>
                  <p className={styles.title}>Custom Mosaics Tear Sheet</p>
                  <button
                    className={styles.buttonContainer}
                    onClick={() => {
                      FileSaver.saveAs(
                        moroccanTile.custom_mosaics.url,
                        moroccanTile.custom_mosaics.url?.split('/').pop(),
                      );
                    }}
                  >
                    <img className={styles.pngButtonImg} alt="icon image" src={savePngIcon} />
                  </button>
                </div>
              )}
            </div>
          </div>
        </section>
        <div className={styles.headerMosaic}>
          <img src={Background2} className={styles.imageMosaic} />
          <div className={styles.border}></div>
          <div className={styles.titleContainer} style={{ height: '18rem' }}>
            <p className={styles.title}>{'Moroccan Mosaics'}</p>
            <p className={styles.text}>CUSTOM</p>
          </div>
        </div>
        <section className={styles.steps}>
          <p className={styles.title}>STEP 1: SELECT LINE ART DESIGN</p>
          <p className={styles.text}>
            *Circle close-up is an example of what the pattern can look like
          </p>

          <div className={styles.mosaicContainer}>
            {morrocanMosaics?.map((item, i) => (
              <div key={i + `${item.name}`} className={styles.tile}>
                <MosaicExample
                  image={item.image_schema}
                  image_colored={item.image_colored}
                  name={item.name}
                />
              </div>
            ))}
          </div>

          <p className={styles.title}>{'STEP 2: SELECT COLOR(S)'}</p>

          <div className={styles.colorsContainer}>
            {mosaicColors?.map((color, i) => (
              <div key={i} className={styles.color}>
                <img src={color.color_image.url} alt="color" className={styles.colorImg} />
                <p className={styles.colorName}>{color.name}</p>
              </div>
            ))}
          </div>

          <div className={styles.stepThree}>
            <p className={styles.stepThreeTitle}> STEP BY STEP PROCESS:</p>

            <div className={styles.processWrapper}>
              <div className={styles.process}>
                <div>
                  <p className={styles.processTitle}>{'STEP(1)'}</p>
                  <p className={cn(styles.processText, 'hide-on-desktop')}>
                    {'Select a pattern \n(from the line art).'}
                  </p>
                  <p className={cn(styles.processText, 'hide-on-tablet')}>
                    {'Select a pattern (from the line art).'}
                  </p>
                </div>
                <img src={`${mos3}`} alt="image" className={styles.processImg} />
              </div>

              <div className={styles.process}>
                <div>
                  <p className={styles.processTitle}>{'STEP(2)'}</p>
                  <p className={cn(styles.processText, 'hide-on-tablet')}>
                    {'Select color(s) (from the color \n grid). Available in over 35 colors.'}
                  </p>
                  <p className={cn(styles.processText, 'hide-on-desktop')}>
                    {'Select color(s) \n(from the color \ngrid). Available in \nover 35 colors.'}
                  </p>
                </div>
                <img src={`${proc2}`} alt="image" className={styles.processImgRounded} />
              </div>

              <div className={styles.process}>
                <div>
                  <p className={styles.processTitle}>{'STEP(3)'}</p>
                  <p className={cn(styles.processText, 'hide-on-desktop')}>
                    {'Your custom pattern \nwill be created'}.
                  </p>
                  <p className={cn(styles.processText, 'hide-on-tablet')}>
                    {'Your custom pattern will be created'}.
                  </p>
                </div>
                <img src={`${mos2}`} alt="image" className={styles.processImg} />
              </div>
            </div>
          </div>
        </section>
      </section>
    </Layout>
  );
};

export default MorrocanTile;
