import React, { FC } from 'react';
import styles from './mosaicExample.module.scss';

interface Props {
  image: {
    url: string;
  };

  image_colored: {
    url: string;
  };

  name: string;
}

const MosaicExample: FC<Props> = ({ image, image_colored, name }: Props) => {
  return (
    <section className={styles.wrapper}>
      {image !== null && <img src={image.url} alt="tile" className={styles.image} />}
      {image_colored !== null && (
        <img src={image_colored.url} alt="tile" className={styles.imageColored} />
      )}

      {name !== null && <p className={styles.name}>{name}</p>}
    </section>
  );
};

export default MosaicExample;
